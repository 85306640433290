import React from 'react'
import Navbar from './LayoutComponents/Navbar'
import { GatsbyImage } from "gatsby-plugin-image";

function Hero(props) {
  return (
    <div style={{minHeight: '50vh'}} className="relative overflow-visible bg-light-black text-my-grey">
      <div className="mx-auto max-w-screen-xl bg-gradient-r-grey-darker">
        <div
          className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-32 xl:pb-36"
          style={{
            backgroundImage:
              'radial-gradient(closest-side at 45% 50%, #262c3a, #1D1E22)',
          }}
        >
          <svg
            className="absolute inset-y-0 right-0 hidden w-48 h-full lg:block text-light-black transform translate-x-1/2"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="50,0 100,0 50,100 0,100" />
          </svg>
          <Navbar logo={props.logo} />

          <main className="px-4 mx-auto mt-10 max-w-screen-xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h2 className="mt-12 text-4xl font-normal tracking-tight text-my-yellow sm:text-5xl sm:leading-none sm:mt-24 lg:ml-8 md:text-6xl">
                {props.colorheading}
                <p className={props.split ? 'hidden' : 'sm:hidden -mt-4'}></p>
                <span className="text-my-grey">{props.heading}</span>
              </h2>
              <h5 className="mt-3 text-base tracking-wide xs:-mt-px sm:mt-5 leading-8 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:ml-8">
                {props.subheading}
              </h5>
            </div>
          </main>
        </div>
      </div>
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:right-0 lg:w-1/2">
        <GatsbyImage image={props.image} className="object-cover w-full h-full" alt="" />
      </div>
    </div>
  );
}

export default Hero
