import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Footer from './Footer'
import 'typeface-oranienbaum'
import 'typeface-titillium-web'
import 'typeface-poppins'

const Layout = ({ children, className }) => {
  return (
    <>
      <Helmet>
        <script
          defer
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon='{"token": "99cbc3dd2a104eef9517b865c5ccdbe1", "spa": true}'
        ></script>
      </Helmet>
      <div className={`relative ${className}`}>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
