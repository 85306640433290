import React from 'react'
import { useState } from 'react'
import Transition from '../Transition.js'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Dropdown from '../Dropdown'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

function dataNavbar() {
  return (
    <>
      <StaticQuery
        query={graphql`
          query LogoQuery {
            file(relativePath: { eq: "transparentlogosmall.png" }) {
              childImageSharp {
                gatsbyImageData(width: 70, layout: CONSTRAINED)
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Navbar data={data} />
          </>
        )}
      />
    </>
  )
}

function Navbar(props) {
  const [isOpen, setIsOpen] = useState(false)
  const page = typeof window !== 'undefined' ? window.location.pathname : ''
  return (
    <div>
      <div className="relative px-4 pt-6 sm:px-6 lg:px-4">
        <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/" aria-label="Home">
                <GatsbyImage
                  image={props.data.file.childImageSharp.gatsbyImageData}
                  className="h-8 sm:h-19"
                  style={{ width: '62px' }}
                  alt="Logo"
                />
              </Link>
              <div className="flex items-center -mr-2 md:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-my-grey hover:text-my-yellow hover:bg-dark-grey focus:outline-none focus:bg-icon-grey focus:text-my-grey transition duration-150 ease-in-out"
                  id="main-menu"
                  aria-label="Main menu"
                  aria-haspopup="true"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <svg
                    className="w-6 h-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:ml-10 md:pr-4">
            {/* <Dropdown item="What We Offer" /> */}
            {/* <Link */}
            {/*   to="/our-team" */}
            {/*   className="ml-8 font-medium text-my-grey hover:text-my-yellow transition duration-150 ease-in-out" */}
            {/* > */}
            {/*   Our Team */}
            {/* </Link> */}
            {/* <AnchorLink */}
            {/*   to={`${page}#bottom`} */}
            {/*   className="ml-8 font-medium text-my-grey hover:text-my-yellow transition duration-150 ease-in-out" */}
            {/* > */}
            {/*   Contact Us */}
            {/* </AnchorLink> */}
          </div>
        </nav>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-150 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute inset-x-0 top-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md">
            <div
              className="overflow-hidden rounded-lg bg-my-dark-grey text-my-grey shadow-xs"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu"
            >
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <Link to="/">
                    <GatsbyImage
                      image={props.data.file.childImageSharp.gatsbyImageData}
                      className="h-8 sm:h-19"
                      style={{ width: '62px' }}
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md hover:text-my-yellow hover:bg-dark-grey focus:outline-none focus:bg-icon-grey focus:text-my-grey transition duration-150 ease-in-out"
                    aria-label="Close menu"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <svg
                      className="w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {/* <div className="px-2 pt-2 pb-3"> */}
              {/*   <h4 className="block px-3 py-2 text-base font-medium rounded-md focus:outline-none transition duration-150 ease-in-out"> */}
              {/*     What We Offer */}
              {/*   </h4> */}
              {/*   <Link */}
              {/*     to="/devops" */}
              {/*     className="block px-3 py-2 ml-8 text-base font-medium rounded-md hover:text-icon-grey hover:bg-my-yellow focus:outline-none transition duration-150 ease-in-out" */}
              {/*     role="menuitem" */}
              {/*   > */}
              {/*     DevOps */}
              {/*   </Link> */}
              {/*   <Link */}
              {/*     to="/cloud-systems" */}
              {/*     className="block px-3 py-2 ml-8 text-base font-medium rounded-md hover:text-icon-grey hover:bg-my-yellow focus:outline-none transition duration-150 ease-in-out" */}
              {/*     role="menuitem" */}
              {/*   > */}
              {/*     Cloud Systems */}
              {/*   </Link> */}
              {/*   <Link */}
              {/*     to="/web-development" */}
              {/*     className="block px-3 py-2 ml-8 text-base font-medium rounded-md hover:text-icon-grey hover:bg-my-yellow focus:outline-none transition duration-150 ease-in-out" */}
              {/*     role="menuitem" */}
              {/*   > */}
              {/*     Web Development */}
              {/*   </Link> */}
              {/*   {/1* <Link *1/} */}
              {/*   {/1*   to="/our-team" *1/} */}
              {/*   {/1*   className="block px-3 py-2 mt-1 text-base font-medium rounded-md hover:text-icon-grey hover:bg-my-yellow focus:outline-none transition duration-150 ease-in-out" *1/} */}
              {/*   {/1*   role="menuitem" *1/} */}
              {/*   {/1* > *1/} */}
              {/*   {/1*   Our Team *1/} */}
              {/*   {/1* </Link> *1/} */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default dataNavbar
