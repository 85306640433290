import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

function Footer() {
  return (
    <div style={{zIndex:0}} className="hidden sm:block w-full absolute bottom-0 lg:h-24 md:h-32 h-10 bg-icon-grey text-my-grey">
    </div>
  )
}

export default Footer
